import { useContext, useEffect, useState } from "react";

import { Box, Button, Stack } from "@mui/material";
import StyledTitle from "../../components/StyledTitle";
import { UserContext } from "../../contexts/user";
import { Plan } from "../../schemas/plan";
import { Link } from "react-router-dom";
import routes from "../../utils/routes";
import styles from "./styles";

const PlansPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<Plan[]>([]);

  const { authFetch } = useContext(UserContext);

  const getPlans = async () => {
    setLoading(true);

    authFetch("/api/plans", "GET", {})
      .then((data) => {
        setLoading(false);
        setPlans(data.plans);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ minWidth: "100%" }}>
      <StyledTitle
        cta={
          <Button
            component={Link}
            to={routes.addPlan}
            variant="contained"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        My Plans
      </StyledTitle>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
          {plans.map((plan) => (
            <Box key={`plan-${plan.id}`} sx={styles.plan}>
              <strong>{plan.name}</strong>
              <Button
                variant="outlined"
                component={Link}
                to={`${routes.plans}/${plan.id}`}
              >
                View Plan
              </Button>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default PlansPage;
