const defaultStyles = {
  root: {
    backgroundColor: "#f3f1ec",
  },
  openGuestDrawer: {
    width: "calc(100% - 240px)",
    left: "240px",
  },
};

export default defaultStyles;
