import { grey } from "@mui/material/colors";

const defaultStyles = {
  tableWrapper: {
    position: "relative",
    width: 200,
    height: 200,
    m: 5,
    border: "1px solid black",
    backgroundColor: "white",
  },
  rectangleTable: {},
  roundTable: {
    borderRadius: "50%",
  },
  table: {
    position: "absolute",
    display: "inline-block",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 10,
    height: 10,
  },
  chair: {
    position: "absolute",
    width: "10px",
    height: "10px",
    top: "50%",
    left: "50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    "&:hover": {
      "& > .removeSeatBadge": {
        display: "block",
      },
    },
  },
  badge: {
    "& .MuiBadge-badge": {
      maxWidth: 80,
      height: "auto",
      padding: "4px",
      textAlign: "center",
    },
  },
  chairAssignMode: {
    cursor: "pointer",
  },
  removeSeatBadge: {
    display: "none",
    cursor: "pointer",
    position: "absolute",
    bottom: "-30px",
    left: "2px",
  },
  centrepiece: {
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tableActions: {
    display: "flex",
    gap: 0.5,
  },
  actionButton: {
    border: `1px solid ${grey[300]}`,
    p: 0.4,
  },
  tableNumber: {
    fontWeight: 600,
  },
  deleteButton: {
    color: "error.main",
  },
  editButton: {
    color: "blue.main",
  },
};

export default defaultStyles;
