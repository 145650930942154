import { Box, SwipeableDrawer, Typography } from "@mui/material";

import styles from "./styles";
import React, { useContext, useEffect, useState } from "react";
import { GuestsContext } from "../../../contexts/guests";

interface MobileDrawerProps {
  children?: React.ReactNode;
  numOfGuests?: number;
}

function MobileDrawer({ children, numOfGuests }: MobileDrawerProps) {
  const drawerBleeding = 50;
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { activeAssignGuest } = useContext(GuestsContext);

  useEffect(() => {
    if (activeAssignGuest) {
      setOpenDrawer(false);
    }
  }, [activeAssignGuest]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openDrawer}
      onClose={() => setOpenDrawer((prevOpen) => !prevOpen)}
      onOpen={() => setOpenDrawer((prevOpen) => !prevOpen)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      swipeAreaWidth={drawerBleeding}
      sx={styles.swipeabledrawer}
    >
      <Box
        sx={[
          styles.swipeable,
          {
            top: -drawerBleeding,
            height: drawerBleeding,
          },
        ]}
      >
        <Box sx={styles.puller} />
        <Typography variant="body2" sx={styles.preview}>
          {numOfGuests} Guests
        </Typography>
      </Box>
      {children}
    </SwipeableDrawer>
  );
}

export default MobileDrawer;
