import { Routes, Route, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { ClerkProvider } from "@clerk/clerk-react";
import SignInPage from "./pages/SignIn";
import SignUpPage from "./pages/SignUp";
import LandingPage from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import PublicContainer from "./pages/PublicContainer";
import { GuestsProvider } from "./contexts/guests";
import PublicPlanPage from "./pages/PublicPlanPage";
import { AlertProvider } from "./contexts/alert";

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export default function App() {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <ThemeProvider theme={theme}>
            <LandingPage />
          </ThemeProvider>
        }
      />

      <Route
        path="/sign-in/*"
        index
        element={
          <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
            <SignInPage />
          </ClerkProvider>
        }
      />
      <Route
        path="/sign-up/*"
        index
        element={
          <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
            <SignUpPage />
          </ClerkProvider>
        }
      />
      <Route path="/event" element={<PublicLayout />}>
        <Route index element={<Layout />} />
        <Route path="/event/:planId" element={<PublicPlanPage />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/dashboard/*" element={<Layout />} />
    </Routes>
  );
}

function PublicLayout() {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <PublicContainer />
    </ClerkProvider>
  );
}

function Layout() {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <AlertProvider>
        <GuestsProvider>
          <Dashboard />
        </GuestsProvider>
      </AlertProvider>
    </ClerkProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
