import { grey } from "@mui/material/colors";

const defaultStyles = {
  plan: {
    border: `1px solid ${grey[400]}`,
    p: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
    borderRadius: 2,
  },
};

export default defaultStyles;
