import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface BackButtonProps {
  path: string;
  title: string;
}

const BackButton: React.FC<BackButtonProps> = ({ path, title }) => {
  return (
    <Button
      component={Link}
      to={path}
      variant="outlined"
      size="small"
      sx={{ mb: 2 }}
    >
      {title}
    </Button>
  );
};

export default BackButton;
