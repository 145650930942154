import { ThemeProvider } from "@emotion/react";
import NavBar from "../../components/NavBar";
import PageContainer from "../../components/PageContainer";
import { Outlet, Route, Routes } from "react-router-dom";
import theme from "../../theme";
import { Box } from "@mui/material";

import { UserProvider } from "../../contexts/user";
import PlansPage from "../PlansPage";
import PlanPage from "../PlanPage";
import AddGuestsPage from "../AddGuestsPage";
import CreatePlanPage from "../CreatePlan";
import AutoAssignPage from "../AutoAssignPage";

function Dashboard() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Box>
          <Routes>
            <Route path="/plans/:planId" element={<NavBar openGuestDrawer />} />
            <Route path="*" element={<NavBar />} />
          </Routes>

          <PageContainer>
            <Outlet />
            <Routes>
              <Route path="/" element={<PlansPage />} />
              <Route path="/plans" element={<PlansPage />} />
              <Route path="/new-plan" element={<CreatePlanPage />} />
              <Route path="/plans/:planId" element={<PlanPage />} />
              <Route
                path="/plans/:planId/guests/add"
                element={<AddGuestsPage />}
              />
              <Route
                path="/plans/:planId/auto-assign"
                element={<AutoAssignPage />}
              />
            </Routes>
          </PageContainer>
        </Box>
      </UserProvider>
    </ThemeProvider>
  );
}

export default Dashboard;
