import React, { createContext, useContext, useState } from "react";
import StyledDialog from "../../StyledDialog";
import { Guest } from "../../../schemas/guest";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { UserContext } from "../../../contexts/user";
import { AlertContext } from "../../../contexts/alert";
import { GuestsContext } from "../../../contexts/guests";
import { LoadingButton } from "@mui/lab";

interface EditGuestContextValue {
  openEditGuest: boolean;
  handleCloseEditGuest: () => void;
  setOpenEditGuest: React.Dispatch<React.SetStateAction<boolean>>;
  setEditGuest: React.Dispatch<React.SetStateAction<Guest | null>>;
  onDeleteGuest: (guest: Guest) => void;
  onEditGuest: (guest: Guest) => void;
}

const initialContextValue: EditGuestContextValue = {
  openEditGuest: false,
  handleCloseEditGuest: () => {},
  setOpenEditGuest: () => {},
  setEditGuest: () => {},
  onDeleteGuest: () => {},
  onEditGuest: () => {},
};

const EditGuestContext =
  createContext<EditGuestContextValue>(initialContextValue);

interface AlertProviderProps {
  children: React.ReactNode;
}

const EditGuestProvider = ({ children }: AlertProviderProps) => {
  const [openEditGuest, setOpenEditGuest] = useState<boolean>(false);
  const [editGuest, setEditGuest] = useState<Guest | null>(null);
  const [deleteGuest, setDeleteGuest] = useState<Guest | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  const { authFetch } = useContext(UserContext);
  const { setAlertMessage } = useContext(AlertContext);
  const { removeGuest, updateGuest } = useContext(GuestsContext);

  const handleCloseEditGuest = () => {
    setEditGuest(null);
    setOpenEditGuest(false);
  };

  const handleCloseDeleteGuest = () => {
    setDeleteGuest(null);
    setConfirmDeleteOpen(false);
  };

  const saveGuest = async () => {
    if (!editGuest || !editGuest.id) return;

    setSaveLoading(true);

    authFetch(`/api/guests/${editGuest?.id}`, "PUT", {
      name: editGuest.name,
    })
      .then((data) => {
        updateGuest(editGuest);
        setSaveLoading(false);

        setAlertMessage({
          message: "Guest updated successfully!",
          severity: "success",
        });

        setEditGuest(null);
        setOpenEditGuest(false);
      })
      .catch((err) => {
        setAlertMessage({
          message: "Something went wrong!",
          severity: "error",
        });
      });
  };

  const confirmDeleteGuest = async () => {
    if (!deleteGuest || !deleteGuest.id) return;
    setDeleteLoading(true);

    authFetch(`/api/guests/${deleteGuest.id}`, "DELETE", {})
      .then((data) => {
        removeGuest(deleteGuest);

        setDeleteGuest(null);
        setConfirmDeleteOpen(false);

        setAlertMessage({
          message: "Guest deleted successfully!",
          severity: "success",
        });
      })
      .finally(() => setDeleteLoading(false))
      .catch((err) => {
        setAlertMessage({
          message: "Something went wrong!",
          severity: "error",
        });
      });
  };

  const onDeleteGuest = (guest: Guest) => {
    setDeleteGuest(guest);
    setConfirmDeleteOpen(true);
  };

  const onEditGuest = (guest: Guest) => {
    setEditGuest(guest);
    setOpenEditGuest(true);
  };

  return (
    <EditGuestContext.Provider
      value={{
        openEditGuest,
        handleCloseEditGuest,
        setOpenEditGuest,
        setEditGuest,
        onDeleteGuest,
        onEditGuest,
      }}
    >
      {children}
      <StyledDialog
        title="Edit Guest"
        open={openEditGuest}
        handleClose={handleCloseEditGuest}
        actions={
          <>
            <Button onClick={handleCloseEditGuest}>Close</Button>
            <LoadingButton
              onClick={saveGuest}
              variant="contained"
              loading={saveLoading}
            >
              Save
            </LoadingButton>
          </>
        }
      >
        <Stack spacing={2}>
          <Typography variant="body2">Edit your guest</Typography>
          <TextField
            label="Name"
            value={editGuest?.name}
            onChange={(e) =>
              setEditGuest((prevGuest) => ({
                ...prevGuest!,
                name: e.target.value,
              }))
            }
          />
        </Stack>
      </StyledDialog>

      <StyledDialog
        title="Confirm Delete"
        open={confirmDeleteOpen}
        handleClose={handleCloseDeleteGuest}
        actions={
          <>
            <Button onClick={handleCloseDeleteGuest}>No</Button>
            <LoadingButton
              onClick={confirmDeleteGuest}
              variant="contained"
              loading={deleteLoading}
            >
              Yes
            </LoadingButton>
          </>
        }
      >
        <Typography variant="body2">
          Are you sure you want to delete this guest?
        </Typography>
      </StyledDialog>
    </EditGuestContext.Provider>
  );
};

export { EditGuestContext, EditGuestProvider };
