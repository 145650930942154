import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";

import styles from "./styles";
import { ChartSorting, Plan } from "../../schemas/plan";
import { Table } from "../../schemas/table";
import { Guest } from "../../schemas/guest";
import DefaultTheme from "./themes/Default";
import GreeneryTheme from "./themes/Greenery";

const PublicPlanPage: React.FC = () => {
  const { planId } = useParams<{ planId: string }>();

  const [loadingPlan, setLoadingPlan] = useState<boolean>(true);
  const [plan, setPlan] = useState<Plan | null>(null);
  const [tables, setTables] = useState<Table[]>([]);
  const [guests, setGuests] = useState<Guest[]>([]);

  const alphabeticalGuests = useMemo(() => {
    let obj: { [key: string]: Guest[] } = {};

    guests.forEach((guest) => {
      if (!obj[guest.name[0]]) {
        obj[guest.name[0]] = [];
      }
      obj[guest.name[0]].push(guest);
    });

    return obj;
  }, [guests]);

  const getPlan = () => {
    setLoadingPlan(true);
    fetch(`/api/plans/public/${planId}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data.plan);
        setPlan(data.plan);
        setTables(data.tables);
        setGuests(data.guests);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingPlan(false);
      });
  };

  useEffect(() => {
    if (planId) {
      getPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  return (
    <Box sx={styles.root}>
      {loadingPlan ? (
        <div>Loading...</div>
      ) : (
        plan && (
          <DefaultTheme>
            <Box sx={styles.board}>
              <Box sx={styles.header}>
                <Typography variant="h1" sx={styles.headerText}>
                  {plan.customization?.title || "Take Your Seat"}
                </Typography>
                {plan.customization?.subtitle && (
                  <Typography variant="body2" sx={styles.headerSubtitle}>
                    {plan.customization?.subtitle}
                  </Typography>
                )}
              </Box>

              {plan.chartSort === ChartSorting.TABLE ? (
                <Grid container spacing={3}>
                  {tables.map((table: Table) => (
                    <Grid item xs={12} sm={6} md={4} key={table.id}>
                      <Box sx={styles.tableContainer}>
                        <Typography variant="h3" gutterBottom>
                          Table #{table.number}
                        </Typography>
                        {table.seatedGuests &&
                          table.seatedGuests.map((guest: Guest) => (
                            <Typography key={guest.id}>{guest.name}</Typography>
                          ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Stack
                  flexWrap={"wrap"}
                  height={800}
                  flexDirection={"column"}
                  gap={4}
                  alignContent={"center"}
                >
                  {Object.keys(alphabeticalGuests).map((letter) => (
                    <Stack
                      key={letter}
                      sx={{ py: 1, width: "calc(50% - 32px)" }}
                    >
                      <Typography variant="h3" sx={styles.letter}>
                        {letter}
                      </Typography>
                      {alphabeticalGuests[letter].map((guest: Guest) => (
                        <Stack
                          key={guest.id}
                          flexDirection={"row"}
                          sx={{ mx: 1 }}
                        >
                          <Box sx={{ flexGrow: 1 }}>{guest.name}</Box>
                          <Box>{guest?.table?.number}</Box>
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              )}
            </Box>
          </DefaultTheme>
        )
      )}
    </Box>
  );
};

export default PublicPlanPage;
