import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  List,
  ListSubheader,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import styles from "./styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { GuestsContext } from "../../contexts/guests";
import { UserContext } from "../../contexts/user";
import routes from "../../utils/routes";
import InfoBox from "../InfoBox";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import MobileDrawer from "./MobileDrawer";
import { AlertContext } from "../../contexts/alert";
import GuestItem from "./GuestItem";
import { EditGuestProvider } from "./EditGuestContext";

function GuestsDrawer() {
  const { planId } = useParams();
  const { guests, setGuests } = useContext(GuestsContext);
  const { authFetch } = useContext(UserContext);
  const { setAlertMessage } = useContext(AlertContext);
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const [loading, setLoading] = useState<boolean>(false);

  const unAssignedGuests = useMemo(() => {
    return guests.filter((guest) => !guest.seat);
  }, [guests]);

  const assignedSeatsGuests = useMemo(() => {
    return guests.filter((guest) => guest.seat);
  }, [guests]);

  const getGuests = async () => {
    setLoading(true);

    authFetch(`/api/plans/${planId}/guests`, "GET", {})
      .then((data) => {
        setGuests(data.guests);
        setLoading(false);
      })
      .catch((err) => {
        setAlertMessage({
          message: "Something went wrong!",
          severity: "error",
        });
      });
  };

  const MainContent = (
    <Box sx={styles.guestContainer}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={styles.drawerHeader}>
            <Box sx={styles.title}>
              <Typography variant="h6">Guests ({guests.length})</Typography>
            </Box>

            <Button
              variant="outlined"
              component={Link}
              to={`${routes.plans}/${planId}/guests/add`}
            >
              Add Guest
            </Button>

            <Button
              component={Link}
              to={`${routes.autoAssign}?autoStart=true`}
              sx={styles.autoAssignButton}
              variant="outlined"
            >
              <span>Auto-Assign</span>
            </Button>
          </Box>

          {guests.length === 0 && (
            <InfoBox
              title="You have no guests yet!"
              text="Start by adding guests for this event."
              icon={<FaceOutlinedIcon />}
            />
          )}
          <EditGuestProvider>
            <List>
              {unAssignedGuests.length > 0 && (
                <>
                  <ListSubheader>Unassigned</ListSubheader>

                  {unAssignedGuests.map((guest) => {
                    return <GuestItem key={guest.id} guest={guest} />;
                  })}
                </>
              )}

              {assignedSeatsGuests.length > 0 && (
                <>
                  {" "}
                  <ListSubheader>Assigned</ListSubheader>
                  {assignedSeatsGuests.map((guest) => {
                    return <GuestItem key={guest.id} guest={guest} />;
                  })}
                </>
              )}
            </List>
          </EditGuestProvider>
        </>
      )}
    </Box>
  );

  useEffect(() => {
    if (planId) {
      getGuests();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  return (
    <Box>
      {isMobileScreen ? (
        <MobileDrawer numOfGuests={guests.length}>{MainContent}</MobileDrawer>
      ) : (
        <Drawer variant="permanent" sx={styles.drawer}>
          {MainContent}
        </Drawer>
      )}
    </Box>
  );
}

export default GuestsDrawer;
