import {
  Box,
  Badge,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  Tooltip,
} from "@mui/material";
import styles from "./styles";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import { Shape, Table } from "../../schemas/table";
import { useContext, useState } from "react";
import { GuestsContext } from "../../contexts/guests";
import { Seat } from "../../schemas/seat";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { UserContext } from "../../contexts/user";
import { AlertContext } from "../../contexts/alert";
import { Rnd, Position } from "react-rnd";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Guest } from "../../schemas/guest";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

type TableProps = {
  table: Table;
  tableIndex: number;
  arrangeMode: boolean;
  onUpdateTablePos: (
    table: Table,
    tableIndex: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => void;
};

const TableMap = ({
  table,
  tableIndex,
  arrangeMode,
  onUpdateTablePos,
}: TableProps) => {
  const {
    assignSeat,
    activeAssignGuest,
    setActiveAssignGuest,
    setGuests,
    removeTable,
    setTables,
  } = useContext(GuestsContext);
  const { setAlertMessage } = useContext(AlertContext);
  const { authFetch } = useContext(UserContext);

  const [deleteOpenConfirm, setDeleteOpenConfirm] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editTable, setEditTable] = useState<Table>(table);
  const [loading, setLoading] = useState<boolean>(false);
  const [addSeatDialogOpen, setAddSeatDialogOpen] = useState<boolean>(false);
  const [numOfSeatsAdded, setNumOfSeatsAdded] = useState<number>(1);

  function deg2rad(deg: number) {
    return (deg * Math.PI) / 180;
  }

  function pos(deg: number, r: number) {
    return {
      x: r * 1.15 * Math.cos(deg2rad(deg)),
      y: r * 1.15 * Math.sin(deg2rad(deg)),
    };
  }

  const calcOneSidedRectangleTablePos = (
    tableHeight: number,
    tableWidth: number,
    seatIndex: number
  ) => {
    const amount = table.seats.length;

    return {
      top: -(tableHeight / 2) - 24,
      left: (seatIndex / (amount - 1)) * tableWidth - tableWidth / 2,
    };
  };

  const renderChair = (seatIndex: number, seat: Seat, tableShape: Shape) => {
    const amount = table.seats.length;
    let chairPos = {};

    if (tableShape === Shape.RECTANGLE) {
      const rectPos = calcOneSidedRectangleTablePos(
        table.height,
        table.width,
        seatIndex
      );
      chairPos = {
        top: rectPos.top + "px",
        left: rectPos.left + "px",
      };
    } else {
      const circPos = pos((seatIndex / amount) * 360, table.width / 2);
      chairPos = { top: circPos.y + "px", left: circPos.x + "px" };
    }

    return (
      <Box
        sx={[
          styles.chair,
          chairPos,
          activeAssignGuest && styles.chairAssignMode,
        ]}
        key={`chair-${seatIndex}`}
        onClick={() => onAssignSeat(seatIndex, seat)}
      >
        {seat && seat.guest ? (
          <Badge
            color="success"
            badgeContent={seat.guest.name}
            sx={styles.badge}
          >
            <ChairAltIcon />
          </Badge>
        ) : (
          <ChairAltIcon />
        )}

        <Tooltip title="Remove seat">
          <RemoveCircleOutlineIcon
            color="error"
            fontSize={"small"}
            sx={styles.removeSeatBadge}
            className="removeSeatBadge"
            onClick={() => onRemoveSeat(seat)}
          />
        </Tooltip>
      </Box>
    );
  };

  const onRemoveSeat = async (seat: Seat) => {
    if (!table.id) return;

    const guestAssigned = seat.guest;

    setLoading(true);
    authFetch(`/api/seats/${seat.id}`, "DELETE", {})
      .then((data) => {
        console.log("seat removed", data);

        if (guestAssigned) {
          delete guestAssigned.seat;
          delete guestAssigned.seatId;
          delete guestAssigned.table;

          setGuests((prevGuests) =>
            prevGuests.map((guest: Guest) =>
              guest.id === guestAssigned.id ? guestAssigned : guest
            )
          );
        }

        setTables((prevTables) =>
          prevTables.map((t: Table) =>
            t.id === table.id
              ? {
                  ...t,
                  seats: t.seats.filter((s) => s.id !== seat.id),
                }
              : t
          )
        );

        setAlertMessage({
          message: "Seat removed",
          severity: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const onAddSeat = async () => {
    if (!table.id) return;

    setLoading(true);
    authFetch(`/api/seats`, "POST", {
      tableId: table.id,
      numOfSeats: numOfSeatsAdded,
    })
      .then((data) => {
        console.log("seat added", data);

        setTables((prevTables) =>
          prevTables.map((t: Table) =>
            t.id === table.id ? { ...t, seats: data.seats } : t
          )
        );

        setAlertMessage({
          message: "Seats Added",
          severity: "success",
        });
        setLoading(false);
        setAddSeatDialogOpen(false);
        setNumOfSeatsAdded(1);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const onAssignGuestToSeat = async (assigningSeat: Seat) => {
    if (!table.id) return;

    console.log("assigning seat", assigningSeat);
    setLoading(true);

    authFetch(`/api/tables/${table.id}/seats`, "POST", {
      guestId: activeAssignGuest?.id,
      seatId: assigningSeat.id,
    })
      .then((data) => {
        setActiveAssignGuest(null);
        setGuests((prevGuests) =>
          prevGuests.map((guest) =>
            guest.id === data.guest.id ? data.guest : guest
          )
        );
        setAlertMessage({
          message: "Guest assigned to seat",
          severity: "success",
        });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const onAssignSeat = (seatIndex: number, seat: Seat) => {
    console.log(seat, activeAssignGuest);
    if (seat && activeAssignGuest) {
      onAssignGuestToSeat(seat);
      assignSeat(tableIndex, seatIndex);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditTable({ ...editTable, [e.target.name]: e.target.value });
  };

  const handleSeatsAddedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumOfSeatsAdded(parseInt(e.target.value));
  };

  const confirmDeleteTable = async () => {
    if (!table.id) return;

    setLoading(true);
    authFetch(`/api/tables/${table.id}`, "DELETE", {})
      .then((data) => {
        console.log("table delete", data);
        if (table.id) {
          removeTable(table.id);
          setDeleteOpenConfirm(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const confirmEditTable = async () => {
    if (!table.id) return;

    setLoading(true);
    authFetch(`/api/tables/${table.id}`, "PUT", {
      number: editTable.number,
      shape: editTable.shape,
    })
      .then((data) => {
        console.log("table edit", data.table);
        setLoading(false);
        setEditOpen(false);

        setTables((prevTables) =>
          prevTables.map((t: Table) =>
            t.id === editTable.id ? { ...t, ...data.table } : t
          )
        );
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const RoundTable = (
    <Box
      sx={[
        styles.tableWrapper,
        table.shape === Shape.ROUND ? styles.roundTable : styles.rectangleTable,
        { top: -40, left: -40, width: table.width, height: table.height },
      ]}
    >
      <Box sx={styles.table}>
        <Box sx={styles.centrepiece}>
          💐
          <Box sx={{ fontSize: "0.8rem" }}>Table</Box>
          <Box sx={styles.tableNumber}>#{table.number}</Box>
          {!arrangeMode && (
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box sx={styles.tableActions}>
                  <IconButton
                    size="small"
                    onClick={() => setDeleteOpenConfirm(true)}
                    sx={[styles.actionButton, styles.deleteButton]}
                  >
                    <DeleteForeverOutlinedIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => setEditOpen(true)}
                    sx={[styles.actionButton, styles.editButton]}
                  >
                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => setAddSeatDialogOpen(true)}
                    sx={[styles.actionButton]}
                  >
                    <PersonAddIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </Box>
              )}
            </>
          )}
        </Box>

        {table.seats.map((e, i) => renderChair(i, e, table.shape))}
      </Box>
    </Box>
  );

  const onDragStop = (e: any, d: Position) => {
    const x = Math.round(d.x);
    const y = Math.round(d.y);

    onUpdateTablePos(table, tableIndex, x, y, table.width, table.height);
  };

  const onResizeStop = (
    e: any,
    direction: any,
    ref: any,
    delta: any,
    position: Position
  ) => {
    onUpdateTablePos(
      table,
      tableIndex,
      Math.round(position.x),
      Math.round(position.y),
      parseInt(ref.style.width) - 20,
      parseInt(ref.style.height) - 20
    );
  };

  return (
    <>
      <Rnd
        style={{
          border: "1px dashed black",
          padding: "8px",
        }}
        default={{
          x: table.positionLeft || 0,
          y: table.positionTop || 0,
          width: table.width + 20 || 200,
          height: table.height + 20 || 200,
        }}
        size={{ width: table.width + 20, height: table.height + 20 }}
        position={{ x: table.positionLeft || 0, y: table.positionTop || 0 }}
        onDragStop={onDragStop}
        disableDragging={!arrangeMode}
        enableResizing={arrangeMode}
        onResizeStop={onResizeStop}
        lockAspectRatio={table.shape === Shape.ROUND}
      >
        {RoundTable}
      </Rnd>

      <Dialog
        open={deleteOpenConfirm}
        onClose={() => setDeleteOpenConfirm(false)}
      >
        <DialogTitle>Delete Table #{table.number}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this table?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpenConfirm(false)}>Cancel</Button>
          <Button onClick={confirmDeleteTable}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Edit Table #{table.number}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <InputLabel>Table Number</InputLabel>
            <TextField
              value={editTable.number}
              onChange={handleChange}
              name="number"
            />

            <InputLabel>Table Shape</InputLabel>
            <Select
              onChange={(e) => {
                setEditTable({ ...editTable, shape: e.target.value as Shape });
              }}
              value={editTable.shape}
            >
              <MenuItem value={Shape.ROUND}>Round</MenuItem>
              <MenuItem value={Shape.RECTANGLE}>Rectangle</MenuItem>
            </Select>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button onClick={confirmEditTable}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addSeatDialogOpen}
        onClose={() => setAddSeatDialogOpen(false)}
      >
        <DialogTitle>Add Seats to Table #{table.number}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <InputLabel>Add Seats</InputLabel>
            <TextField
              value={numOfSeatsAdded}
              onChange={handleSeatsAddedChange}
              type="number"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddSeatDialogOpen(false)}>Cancel</Button>
          <Button onClick={onAddSeat}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableMap;
