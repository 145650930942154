import React from "react";
import styles from "./styles";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../utils/routes";

const Logo = require("../../assets/logo.png");
const HeadImage = require("./assets/Wedding Round Tables.png");
const Belle = require("./assets/Belle.png");
const Printer = require("./assets/printer.png");
const PlanningIcon = require("./assets/planning.png");
const ScanIcon = require("./assets/scan.png");

const LandingPage: React.FC = () => {
  return (
    <>
      <Box sx={[styles.root]}>
        <AppBar position="fixed" sx={styles.navbar} color="transparent">
          <Toolbar>
            <img
              src={Logo}
              alt="Seatmap Logo"
              style={{ width: "80px", height: "auto" }}
            />
            <Box sx={styles.navbarLinks}>
              <Button component={Link} to={routes.signIn}>
                Log In
              </Button>
              <Button variant="contained" component={Link} to={routes.signUp}>
                Get started - it's free
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={styles.heroContainer}>
          <Stack direction={"column"} alignItems="center" spacing={3}>
            <Box sx={styles.heroTextContainer}>
              <Typography variant="h1" sx={styles.headerText}>
                <span style={{ color: "#528c03" }}>Effortless</span> Seating
                Charts
              </Typography>
              <Typography variant="caption" sx={styles.description}>
                Create, Manage, and Customize Wedding Seating Arrangements with
                Ease
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={routes.signUp}
              >
                Start Planning Now
              </Button>
            </Box>
            <Box sx={{ maxWidth: { xs: "100%", md: "60%" } }}>
              <img
                src={HeadImage}
                alt="Round Table Seating Arrangement Example"
                width="100%"
              />
            </Box>
          </Stack>
        </Box>
        <Typography variant="h2" gutterBottom>
          Overview
        </Typography>
        <Box
          sx={{
            width: { xs: "100%", sm: "60%", md: "600px", lg: "800px" },
            height: { xs: 400, md: 400 },
          }}
        >
          <Box
            sx={{
              position: "relative",
              paddingBottom: "56.25%",
              paddingTop: "25px",
              height: 0,
              "& iframe": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              },
            }}
          >
            <iframe
              src="https://www.loom.com/embed/9ee9f1b356874975907effab77e34b5b?sid=a9563101-9b33-450f-8b54-c5f8228ef214"
              allowFullScreen
              title="SeatMap Demo Video for Weddings and Events"
            ></iframe>
          </Box>
        </Box>
        <Box sx={styles.infoContainer}>
          <Typography variant="h2" gutterBottom>
            Key Features
          </Typography>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={3}>
              <Box sx={styles.infoSection}>
                <Typography variant="h3" gutterBottom>
                  Easy Assign Interface
                </Typography>
                <img src={PlanningIcon} alt="Planning Icon" />
                <Typography variant="body1">
                  Design your seating plan in minutes. Import guest lists and
                  assign seats effortlessly.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={styles.infoSection}>
                <Typography variant="h3" gutterBottom>
                  Meet Belle
                </Typography>
                <img src={Belle} alt="Belle the AI Assistant" />
                <Typography variant="body1">
                  Your AI assistant to help you with your seating plan!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={styles.infoSection}>
                <Typography variant="h3" gutterBottom>
                  QR Code
                </Typography>
                <img src={ScanIcon} alt="Scan Icon" />
                <Typography variant="body1">
                  Generate QR codes for guests to easily find their seats.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={styles.infoSection}>
                <Typography variant="h3" gutterBottom>
                  Print-Friendly Options
                </Typography>
                <img src={Printer} alt="Printer Icon" />
                <Typography variant="body1">
                  Print your seating chart at your own printing house or share
                  digitally.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.footer}>2024 SeatMap. All right reserved.</Box>
      </Box>
    </>
  );
};

export default LandingPage;
