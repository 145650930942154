import { useContext, useState } from "react";

import { Box, Stack, TextField } from "@mui/material";
import StyledTitle from "../../components/StyledTitle";
import { LoadingButton } from "@mui/lab";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";

const CreatePlanPage = () => {
  const { authFetch } = useContext(UserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "blue",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    authFetch("/api/plans", "POST", formData)
      .then((data) => {
        navigate(`/dashboard/plans/${data.plan.id}`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <StyledTitle>Create Plan</StyledTitle>

      <form onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <TextField
            name="name"
            label="What are we planning for?"
            placeholder="Wedding 2024"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
          />

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Create Plan
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default CreatePlanPage;
