const defaultStyles = {
  root: {},
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",

    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
      backgroundColor: "#E4E2DD",
    },
  },
  guestContainer: {
    overflow: "scroll",
  },
  drawerHeader: {
    p: 2,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  autoAssignButton: {
    position: "relative",
    display: "inline-block",
    boxSize: "border-box",
    background: "linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4)",
    backgroundSize: "400%",
    borderRadius: "30px",
    zIndex: 1,
    "& span": {
      color: "white",
    },
    "&:hover": {
      "@keyframes animate": {
        "0%": {
          backgroundPosition: "0%",
        },
        "100%": {
          backgroundPosition: "400%",
        },
      },
      animation: "animate 8s linear infinite",
    },
  },

  title: { flexGrow: 1 },
};

export default defaultStyles;
