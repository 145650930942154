import { Box, Typography } from "@mui/material";

import styles from "./styles";

interface InfoBoxProps {
  text: string;
  icon: JSX.Element;
  title?: string;
}

const InfoBox = ({ text, icon, title }: InfoBoxProps) => {
  return (
    <Box sx={styles.root}>
      {icon}
      <Box sx={styles.infoBoxValue}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="caption">{text}</Typography>
      </Box>
    </Box>
  );
};

export default InfoBox;
