const defaultStyles = {
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    mb: 1,
    flexDirection: { xs: "column", md: "row" },
  },
};

export default defaultStyles;
