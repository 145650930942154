import { grey } from "@mui/material/colors";

const defaultStyles = {
  root: {},
  swipeabledrawer: {
    display: "block",
    ".MuiDrawer-paper": {
      height: "calc(100% - 64px)",
      top: 64,
      overflow: "visible",
    },
  },
  swipeable: {
    position: "absolute",
    backgroundColor: "white",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    visibility: "visible",
    right: 0,
    left: 0,
  },
  puller: {
    width: 30,
    height: 6,
    backgroundColor: grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  },
  preview: {
    textAlign: "center",
    py: 2,
  },
};

export default defaultStyles;
