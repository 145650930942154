const defaultStyles = {
  root: {
    p: 2,
    maxWidth: 1200,
    margin: "0 auto",
    display: "block",
  },
  board: {
    backgroundColor: "white",
    p: { xs: 2, sm: 3, md: 5 },
  },
  boardHeader: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
  },
  header: {
    textAlign: "center",
    mb: 3,
  },
  headerText: {},
  headerSubtitle: {
    fontSize: "1.25rem",
  },
  tableContainer: {
    textAlign: "center",
  },
  letter: {
    mb: 2,
  },
};

export default defaultStyles;
