import { Guest } from "./guest";
import { Seat } from "./seat";

export enum Shape {
  ROUND = "Round",
  RECTANGLE = "Rectangle",
}

export type Table = {
  id?: number;
  seats: Seat[];
  number?: number;
  seatedGuests?: Guest[];
  positionLeft: number;
  positionTop: number;
  shape: Shape;
  width: number;
  height: number;
};

export type PreviewTable = {
  number: number;
  guests: string[];
};
