import React, { createContext, useState, SetStateAction } from "react";
import { Guest } from "../schemas/guest";
import { Table as TableSchema } from "../schemas/table";
import { Box, Button, Snackbar } from "@mui/material";

interface GuestsContextValue {
  guests: Guest[];
  setGuests: React.Dispatch<SetStateAction<Guest[]>>;
  activeAssignGuest: Guest | null;
  setActiveAssignGuest: React.Dispatch<SetStateAction<Guest | null>>;
  tables: TableSchema[];
  setTables: React.Dispatch<SetStateAction<TableSchema[]>>;
  assignSeat: (tableIndex: number, seatIndex: number) => void;
  removeTable: (tableId: number) => void;
  openGuestDrawer: boolean;
  setOpenGuestDrawer: React.Dispatch<SetStateAction<boolean>>;
  removeGuest: (guest: Guest) => void;
  updateGuest: (guest: Guest) => void;
}

const initialContextValue: GuestsContextValue = {
  guests: [],
  setGuests: () => {},
  activeAssignGuest: null,
  setActiveAssignGuest: () => {},
  tables: [],
  setTables: () => {},
  assignSeat: () => {},
  removeTable: () => {},
  openGuestDrawer: false,
  setOpenGuestDrawer: () => {},
  removeGuest: () => {},
  updateGuest: () => {},
};

const GuestsContext = createContext<GuestsContextValue>(initialContextValue);

interface GuestsProviderProps {
  children: React.ReactNode;
}

const GuestsProvider = ({ children }: GuestsProviderProps) => {
  const [guests, setGuests] = useState<Guest[]>([]);
  const [activeAssignGuest, setActiveAssignGuest] = useState<Guest | null>(
    null
  );
  const [tables, setTables] = useState<TableSchema[]>([]);
  const [openGuestDrawer, setOpenGuestDrawer] = useState<boolean>(false);

  const assignSeat = (assignTableIndex: number, assignSeatIndex: number) => {
    console.log(
      `assign seating to table#${assignTableIndex} on seat index ${assignSeatIndex}`
    );

    setTables((prevTables) => [
      ...prevTables.map((table, tableIndex) =>
        assignTableIndex === tableIndex
          ? {
              ...table,
              seats: table.seats.map((seat, seatIndex) => {
                if (seat?.guest && activeAssignGuest?.id === seat?.guest?.id) {
                  return { ...seat, guest: null };
                } else if (seatIndex === assignSeatIndex) {
                  return {
                    ...seat,
                    guest: activeAssignGuest,
                  };
                }
                return seat;
              }),
            }
          : {
              ...table,
              seats: table.seats.map((seat) => {
                if (seat?.guest && seat?.guest?.id === activeAssignGuest?.id) {
                  return { ...seat, guest: null };
                }
                return seat;
              }),
            }
      ),
    ]);
  };

  const removeTable = (tableId: number) => {
    setTables((prevTables) =>
      prevTables.filter((table) => table.id !== tableId)
    );
  };

  const removeGuest = (guest: Guest) => {
    setGuests((prevGuests) => prevGuests.filter((g) => g.id !== guest.id));

    setTables((prevTables) =>
      prevTables.map((table) => ({
        ...table,
        seats: table.seats.map((seat) => {
          if (seat.guest?.id === guest.id) {
            return { ...seat, guest: null };
          }
          return seat;
        }),
      }))
    );
  };

  const updateGuest = (guest: Guest) => {
    setGuests((prevGuests) =>
      prevGuests.map((g) => (g.id === guest.id ? guest : g))
    );

    setTables((prevTables) =>
      prevTables.map((table) => ({
        ...table,
        seats: table.seats.map((seat) => {
          if (seat.guest?.id === guest.id) {
            return { ...seat, guest };
          }
          return seat;
        }),
      }))
    );
  };

  const resetActiveAssignGuest = () => {
    setActiveAssignGuest(null);
  };

  return (
    <GuestsContext.Provider
      value={{
        guests,
        setGuests,
        activeAssignGuest,
        setActiveAssignGuest,
        tables,
        setTables,
        assignSeat,
        removeTable,
        openGuestDrawer,
        setOpenGuestDrawer,
        removeGuest,
        updateGuest,
      }}
    >
      {children}

      <Snackbar
        open={!!activeAssignGuest}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            color: "black",
            py: 2,
            px: 4,
            borderRadius: 50,
            boxShadow: 5,
          }}
        >
          <strong>{activeAssignGuest?.name}</strong> is being assigned...
          <Button
            onClick={resetActiveAssignGuest}
            size="small"
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </Snackbar>
    </GuestsContext.Provider>
  );
};

export { GuestsContext, GuestsProvider };
