const defaultStyles = {
  tablesContainer: {
    width: "100%",
    height: 600,
    overflow: "scroll",
    backgroundColor: "white",
    backgroundImage:
      "linear-gradient(0deg, transparent 24px, #d9d9d9 25px, #d9d9d9 26px, transparent 27px), linear-gradient(90deg, transparent 24px, #d9d9d9 25px, #d9d9d9 26px, transparent 27px)",
    backgroundSize: "25px 25px",
  },
  tableMoveableWrapper: {
    width: "100%",
    position: "relative",
  },
  groupButton: {
    "& .MuiButtonBase-root": {
      display: "flex",
      gap: 1.5,
    },
  },
  modeHeader: {
    p: 2,
    boxShadow: 2,
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: "secondary.light",
    color: "white",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButtonBase-root": {
      color: "white",
      borderColor: "white",
    },
  },
  zoomContainer: {
    position: "absolute",
    bottom: 20,
    right: 20,
  },
  zoomButton: {
    backgroundColor: "white",
  },
};

export default defaultStyles;
