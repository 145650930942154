import {
  Box,
  Button,
  ButtonGroup,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

import styles from "./styles";
import { useContext } from "react";
import { GuestsContext } from "../../../contexts/guests";
import { Guest } from "../../../schemas/guest";
import { EditGuestContext } from "../EditGuestContext";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface GuestItemProps {
  guest: Guest;
}

function GuestItem({ guest }: GuestItemProps): JSX.Element {
  const { setActiveAssignGuest, activeAssignGuest } = useContext(GuestsContext);
  const { onDeleteGuest, onEditGuest } = useContext(EditGuestContext);

  const onAssignGuest = (guest: Guest) => {
    setActiveAssignGuest(guest);
  };

  return (
    <ListItem
      key={`guest-assigning-${guest.id}`}
      sx={[
        styles.guestItem,
        activeAssignGuest?.id === guest.id && styles.activeGuestItem,
        activeAssignGuest &&
          activeAssignGuest.id !== guest.id &&
          styles.inactiveItem,
      ]}
    >
      <Box sx={styles.guestName}>
        <Typography variant="body1">{guest.name}</Typography>
      </Box>

      <Box sx={styles.tableLabel}>
        {guest.seat?.table?.number
          ? `Table #${guest.seat?.table?.number}`
          : "Unassigned"}
      </Box>
      <Stack direction="row">
        <ButtonGroup size="small">
          <Button onClick={() => onEditGuest(guest)}>
            <EditOutlinedIcon sx={{ fontSize: 14 }} />
          </Button>
          <Button onClick={() => onDeleteGuest(guest)}>
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 14 }} />
          </Button>
          <Button
            onClick={() => onAssignGuest(guest)}
            variant="outlined"
            disabled={!!activeAssignGuest}
          >
            {activeAssignGuest?.id === guest.id ? "Assigning" : "Assign"}
          </Button>
        </ButtonGroup>
      </Stack>
    </ListItem>
  );
}

export default GuestItem;
