import React from "react";
import { ThemeProvider } from "@mui/material";

import { Helmet, HelmetProvider } from "react-helmet-async";
import theme from "./theme";

const DefaultTheme = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>

        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </HelmetProvider>
    </>
  );
};

export default DefaultTheme;
