import { Grid } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/user";

const PageContainer = ({ children }: { children: React.ReactNode }) => {
  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();

  const { setCurrentUser, authFetch } = useContext(UserContext);

  const getUser = async () => {
    authFetch(`/api/users`, "GET", {})
      .then((data) => {
        // setUserBoxes(data.boxes);
        setCurrentUser(data.user);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/sign-in");
    } else if (isLoaded) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        maxWidth: 1600,
        width: "100%",
        margin: "0 auto",
        px: { xs: 2, sm: 3, md: 5 },
        py: { xs: 2, sm: 3, md: 1 },
        display: "block",
        flexGrow: 1,
      }}
    >
      {children}
    </Grid>
  );
};

export default PageContainer;
