import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/clerk-react";

import styles from "./styles";
import routes from "../../utils/routes";
const Logo = require("../../assets/logo.png");

interface NavBarProps {
  openGuestDrawer?: boolean;
}

const NavBar = ({ openGuestDrawer = false }: NavBarProps) => {
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  return (
    <AppBar
      position="sticky"
      color="transparent"
      sx={[
        styles.root,
        openGuestDrawer && !isMobileScreen && styles.openGuestDrawer,
      ]}
    >
      <Toolbar>
        <Link to="/dashboard">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "80px", height: "auto" }}
          />
        </Link>

        <Button component={Link} to={routes.plans} color="inherit" size="small">
          Home
        </Button>

        <Box
          sx={{ flexGrow: 2, display: "flex", justifyContent: "end", gap: 1 }}
        >
          <Button href="https://forms.gle/egq8tKdqDJsoYfer7" target="_blank">
            Feedback
          </Button>
          <SignedOut>
            <SignInButton />
          </SignedOut>
          <SignedIn>
            <UserButton />
          </SignedIn>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
