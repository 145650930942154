const routes = {
  home: "/dashboard",
  signUp: "/sign-up",
  signIn: "/sign-in",
  create: "/dashboard/create",
  plans: "/dashboard/plans",
  addPlan: "/dashboard/new-plan",
  autoAssign: "auto-assign",
};

export default routes;
