import { grey } from "@mui/material/colors";

const defaultStyles = {
  root: {
    minHeight: "calc(100vh - 32px)",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    p: 2,
  },

  description: {
    fontSize: { xs: "1.2rem", md: "1.5rem" },
    px: 2,
    py: 1,
    display: "block",
  },
  navbar: {
    boxShadow: 1,
    backgroundColor: "background.default",
  },
  navbarLinks: {
    flexGrow: 1,
    textAlign: "right",
  },
  headerText: {
    fontSize: "4rem",
    fontWeight: 600,
  },
  heroTextContainer: {
    display: "block",
  },
  heroContainer: {
    flexGrow: 2,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: { xs: "80vh", sm: "auto", md: "75vh" },
    zIndex: 2,
    mt: 8,
    mb: 2,
    pt: { xs: 0, md: 8 },
  },
  infoContainer: {
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 2,
  },
  infoSection: {
    backgroundColor: grey[50],
    p: 2,
    borderRadius: 2,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    "& img": {
      width: "80px",
    },
  },
  footer: {},
};

export default defaultStyles;
