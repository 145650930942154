import { SignUp } from "@clerk/clerk-react";
import { Grid } from "@mui/material";
import routes from "../../utils/routes";

export default function SignUpPage() {
  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        maxWidth: 1600,
        height: "100vh",
        margin: "0 auto",
        p: 5,
        alignItems: "center",
      }}
    >
      <SignUp
        path="/sign-up"
        signInUrl="/sign-in"
        forceRedirectUrl={routes.home}
      />
    </Grid>
  );
}
