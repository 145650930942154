import { Customization } from "./customization";

export enum ChartSorting {
  ALPHABETICAL = "Alphabetical",
  TABLE = "Table",
}

export type Plan = {
  id?: number;
  name: string;
  description: string;
  chartSort: ChartSorting;
  customization: Customization;
  isLocked: boolean;
};
