import React from "react";
import { Outlet } from "react-router-dom";

interface PublicContainerProps {}

const PublicContainer: React.FC<PublicContainerProps> = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default PublicContainer;
