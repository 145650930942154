import React, { useContext, useState } from "react";
import { ChartSorting, Plan } from "../../schemas/plan";
import StyledDialog from "../StyledDialog";
import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts/user";
import { AlertContext } from "../../contexts/alert";

interface EditPlanDialogProps {
  plan: Plan;
  setPlan: React.Dispatch<React.SetStateAction<Plan | undefined>>;
  open: boolean;
  handleClose: () => void;
}

const EditPlanDialog = ({
  plan,
  setPlan,
  open,
  handleClose,
}: EditPlanDialogProps) => {
  const [formData, setFormData] = useState<Plan>(plan);
  const [customizationData, setCustomizationData] = useState(
    plan.customization || { title: "", subtitle: "" }
  );

  const { authFetch } = useContext(UserContext);
  const { setAlertMessage } = useContext(AlertContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCustomizationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomizationData({
      ...customizationData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    authFetch(`/api/plans/${plan.id}`, "PUT", {
      name: formData.name,
      chartSort: formData.chartSort,
      customization: customizationData,
    })
      .then((data) => {
        setAlertMessage({
          message: "Plan successfully updated",
          severity: "success",
        });
        setPlan(data.plan);
        handleClose();
      })
      .catch((err) => {
        setAlertMessage({
          message: "Failed to update plan",
          severity: "error",
        });
      });
  };

  return (
    <StyledDialog
      title="Edit Plan"
      open={open}
      handleClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Stack spacing={2}>
        <Typography variant="body2">You can edit this plan</Typography>
        <TextField
          value={formData.name}
          onChange={handleChange}
          label="Name"
          name="name"
          fullWidth
        />

        <Box>
          <FormHelperText>
            Select your chart sorting method. We recommend Alphabetical sorting
            for a large guest list.
          </FormHelperText>

          <Select
            label="Chart Sorting"
            value={formData.chartSort}
            fullWidth
            onChange={(e) =>
              setFormData((prevFormData) => {
                return {
                  ...prevFormData,
                  chartSort: e.target.value as ChartSorting,
                };
              })
            }
          >
            <MenuItem value={ChartSorting.ALPHABETICAL}>
              {ChartSorting.ALPHABETICAL}
            </MenuItem>
            <MenuItem value={ChartSorting.TABLE}>{ChartSorting.TABLE}</MenuItem>
          </Select>
        </Box>

        <Typography variant="h5">Page Customizations</Typography>
        <TextField
          value={customizationData.title}
          fullWidth
          label="Page Title"
          name="title"
          onChange={handleCustomizationChange}
          autoComplete="off"
        />
        <TextField
          value={customizationData.subtitle}
          fullWidth
          label="Subtitle"
          name="subtitle"
          onChange={handleCustomizationChange}
          autoComplete="off"
        />
      </Stack>
    </StyledDialog>
  );
};

export default EditPlanDialog;
