import { useContext, useState } from "react";
import { AlertContext } from "../../contexts/alert";
import { GuestsContext } from "../../contexts/guests";
import { UserContext } from "../../contexts/user";
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Shape } from "../../schemas/table";

const DEFAULT_SEATS = 10;
const DEFAULT_SHAPE = Shape.ROUND;

interface AddTableProps {
  planId: number;
}

const AddTable = ({ planId }: AddTableProps) => {
  const { setTables } = useContext(GuestsContext);
  const { setAlertMessage } = useContext(AlertContext);
  const { authFetch } = useContext(UserContext);

  const [addTableLoading, setAddTableLoading] = useState<boolean>(false);
  const [numberOfSeats, setNumberOfSeats] = useState<number>(DEFAULT_SEATS);
  const [selectedShape, setSelectedShape] = useState<Shape>(DEFAULT_SHAPE);

  const onAddTable = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setAddTableLoading(true);

    authFetch(`/api/plans/${planId}/tables`, "POST", {
      numOfSeats: numberOfSeats,
      shape: selectedShape,
    })
      .then((data) => {
        console.log("table", data);
        setAlertMessage({
          message: "Table added successfully!",
          severity: "success",
        });
        setTables((prevTables) => [...prevTables, data.table]);
        setAddTableLoading(false);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <form onSubmit={onAddTable}>
      <Stack gap={2} direction="row" alignItems="center">
        <Select
          value={selectedShape}
          onChange={(e) => setSelectedShape(e.target.value as Shape)}
        >
          <MenuItem value={Shape.ROUND}>Round</MenuItem>
          <MenuItem value={Shape.RECTANGLE}>Rectangle</MenuItem>
        </Select>
        <TextField
          label="Number of Seats"
          type="number"
          name="numberOfSeats"
          onChange={(e) => setNumberOfSeats(parseInt(e.target.value) || 10)}
          value={numberOfSeats}
          fullWidth={false}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          loading={addTableLoading}
        >
          Add Table
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default AddTable;
