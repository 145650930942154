import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PreviewGuest } from "../../schemas/guest";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LoadingButton } from "@mui/lab";
import { AlertContext } from "../../contexts/alert";

interface PreviewImportGuestsProps {
  planId: number;
  previewGuests: PreviewGuest[];
  setPreviewGuests: (previewGuests: PreviewGuest[]) => void;
}
const PreviewImportGuests = ({
  previewGuests,
  planId,
  setPreviewGuests,
}: PreviewImportGuestsProps) => {
  const { authFetch } = useContext(UserContext);
  const { setAlertMessage } = useContext(AlertContext);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const onConfirmImport = () => {
    setConfirmLoading(true);
    authFetch(`/api/guests/confirm-import`, "POST", {
      planId: planId,
      data: previewGuests,
    })
      .then((data) => {
        console.log(data);
        setConfirmLoading(false);
        setAlertMessage({
          message: "Guests imported successfully!",
          severity: "success",
        });
        setPreviewGuests([]);
      })
      .catch((err) => {
        console.log(err);
        setConfirmLoading(false);
        setAlertMessage({
          message:
            "Failed to import guests. Please refresdh and try again or contact support.",
          severity: "error",
        });
      });
  };

  const removePreviewGuest = (guestIndex: number) => {
    const filteredGuests = previewGuests.filter(
      (_, index) => index !== guestIndex
    );
    setPreviewGuests(filteredGuests);
  };

  return (
    <div>
      <h3>Preview Import</h3>

      <TableContainer sx={{ maxHeight: 350 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Table Number</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {previewGuests.map((guest, index) => (
              <TableRow key={`preview-guest-${index}`}>
                <TableCell>{guest.name}</TableCell>
                <TableCell>{guest.email}</TableCell>
                <TableCell>{guest.tableNumber}</TableCell>
                <TableCell>
                  {guest.conflictingEmail && (
                    <Typography variant="body2" color="error">
                      <strong>
                        Guest with same e-mail exists, this will not be
                        imported.
                      </strong>
                    </Typography>
                  )}
                  {guest.sameName && (
                    <Typography variant="body2" color="error">
                      Guest name already exists, this will create a duplicate.
                    </Typography>
                  )}
                  {!guest.validTableNum && (
                    <Typography variant="body2" color="error">
                      Table number is invalid. Guest will not be automatically
                      assigned.
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => removePreviewGuest(index)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <LoadingButton
        variant="contained"
        color="primary"
        onClick={onConfirmImport}
        loading={confirmLoading}
      >
        Confirm Import
      </LoadingButton>
    </div>
  );
};

export default PreviewImportGuests;
