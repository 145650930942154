import { grey } from "@mui/material/colors";

const defaultStyles = {
  root: {},

  autoAssignButton: {
    position: "relative",
    display: "inline-block",
    boxSize: "border-box",
    background: "linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4)",
    backgroundSize: "400%",
    borderRadius: "30px",
    zIndex: 1,
    "& span": {
      color: "white",
    },
    "&:hover": {
      "@keyframes animate": {
        "0%": {
          backgroundPosition: "0%",
        },
        "100%": {
          backgroundPosition: "400%",
        },
      },
      animation: "animate 8s linear infinite",
    },
  },
  weddingPlanner: {
    width: 25,
  },
  chatHistory: {
    backgroundColor: "white",
    p: 3,
    borderRadius: 2,
    mb: 2,
    height: 300,
    overflow: "scroll",
    display: "flex",
    gap: 1,
    flexDirection: "column",
  },
  chatRow: {},
  chatBubble: {
    backgroundColor: grey[100],
    p: 2,
    borderRadius: 4,
  },
  plannerBubble: {
    borderTopLeftRadius: 0,
    mr: "80px !important",
  },
  userBubble: {
    borderBottomRightRadius: 0,
    backgroundColor: "secondary.main",
    color: "white",
    ml: "80px !important",
  },
  typingBubbleContainer: {
    backgroundColor: grey[100],
    display: "flex",
    width: 60,
    height: 40,
    borderRadius: "20px",
    margin: "0 1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  typingBubble: {
    backgroundColor: "#8d8d8d",
    margin: "3px",
    borderRadius: "50%",
    height: 10,
    width: 10,
    animation: "bounce 1000ms ease-in-out infinite",
    animationDelay: "3600ms",
    "@keyframes bounce": {
      "0%, 33%, 100%": {
        transform: "translateY(0)",
      },
      "50%": {
        transform: "translateY(-5px)",
      },
    },
    "&:nth-of-type(1)": {
      animationDelay: "0ms",
    },
    "&:nth-of-type(2)": {
      animationDelay: "333ms",
    },
    "&:nth-of-type(3)": {
      animationDelay: "666ms",
    },
  },
};

export default defaultStyles;
