import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface StyledDialogProps {
  children: React.ReactNode;
  title: string;
  open: boolean;
  handleClose: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  actions?: React.ReactNode;
}

const StyledDialog = ({
  children,
  title,
  open,
  handleClose,
  maxWidth = "xs",
  actions,
}: StyledDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default StyledDialog;
