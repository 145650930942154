const defaultStyles = {
  root: {
    p: 2,
    backgroundColor: "#f3e8b7",
    my: 2,
    mx: 1,
    display: "flex",
    flexWrap: "no-wrap",
    gap: 2,
    alignItems: "center",
  },
  infoBoxValue: {},
};

export default defaultStyles;
