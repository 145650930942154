import { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Link as MuiLink,
  ButtonGroup,
  IconButton,
  Stack,
} from "@mui/material";
import StyledTitle from "../../components/StyledTitle";
import { GuestsContext } from "../../contexts/guests";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../../contexts/user";
import GuestsDrawer from "../../components/GuestsDrawer";
import { Plan } from "../../schemas/plan";
import InfoBox from "../../components/InfoBox";
import TableBarOutlinedIcon from "@mui/icons-material/TableBarOutlined";
import { AlertContext } from "../../contexts/alert";
import StyledDialog from "../../components/StyledDialog";
import QRCode from "react-qr-code";
import { toJpeg } from "html-to-image";
import AddTable from "../../components/AddTable";
import { ZoomOut, ZoomIn } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import styles from "./styles";
import EditPlanDialog from "../../components/EditPlanDialog";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Table as TableSchema } from "../../schemas/table";
import Table from "../../components/Table";

const PlanPage = () => {
  const { planId } = useParams();

  const { tables, setTables } = useContext(GuestsContext);
  const { setAlertMessage } = useContext(AlertContext);
  const { authFetch } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<Plan>();
  const [openQRCode, setOpenQRCode] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(100); // in percentage
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const getPlan = async () => {
    setLoading(true);

    authFetch(`/api/plans/${planId}`, "GET", {})
      .then((data) => {
        const dataPlan = data.plan;
        setTables(data.plan.tables);
        setPlan({
          id: dataPlan.id,
          name: dataPlan.name,
          description: dataPlan.description,
          chartSort: dataPlan.chartSort,
          customization: dataPlan.customization,
          isLocked: dataPlan.isLocked,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const onSaveArrangement = async (
    tableId: number,
    positionLeft: number,
    positionTop: number,
    width: number,
    height: number
  ) => {
    if (!planId) return;

    authFetch(`/api/tables/${tableId}`, "PUT", {
      positionLeft,
      positionTop,
      width,
      height,
    })
      .then((data) => {
        console.log("saved");
      })
      .catch((err) => {
        console.log("Something went wrong!");
        console.log(err);
      });
  };

  const saveQRCode = async () => {
    let imageBlob = "";
    const node = document.getElementById("divToPrint");

    if (!node) return;

    await toJpeg(node)
      .then(function (dataUrl) {
        imageBlob = dataUrl;

        const link = document.createElement("a");
        link.download = "QRCode.jpeg";
        link.href = imageBlob;
        link.click();

        // return imageBlob;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });

    console.log(imageBlob);

    // return imageBlob;
  };

  const handleSaveLock = (locked: boolean) => {
    authFetch(`/api/plans/${planId}`, "PUT", {
      isLocked: locked,
    })
      .then((data) => {
        setAlertMessage({
          message: locked ? "Floor Plan is locked" : "Floor Plan is unlocked",
          severity: "success",
        });
        setPlan(data.plan);
      })
      .catch((err) => {
        setAlertMessage({
          message: "Failed to update plan",
          severity: "error",
        });
      });
  };

  const onUpdateTablePos = (
    table: TableSchema,
    tableIndex: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    setTables((prevTables: TableSchema[]) =>
      prevTables.map((t, i) =>
        i === tableIndex
          ? {
              ...t,
              positionLeft: x,
              positionTop: y,
              width,
              height,
            }
          : t
      )
    );

    // should save by table id
    if (table.id) {
      onSaveArrangement(table.id, x, y, width, height);
    }
  };

  useEffect(() => {
    if (planId) {
      getPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  return (
    <Box sx={{ display: "flex" }}>
      <GuestsDrawer />
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {loading && <CircularProgress />}

        {plan && (
          <>
            <StyledTitle
              cta={
                <Stack direction={"row"} gap={1}>
                  <Button
                    variant="contained"
                    component={Link}
                    to={`/event/${plan.id}`}
                    target="_blank"
                  >
                    View Seating Chart
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => setOpenQRCode(true)}
                  >
                    QR Code
                  </Button>
                  <IconButton color="primary" onClick={() => setOpenEdit(true)}>
                    <SettingsIcon />
                  </IconButton>
                </Stack>
              }
            >
              {plan.name}
            </StyledTitle>
            {tables.length === 0 && (
              <InfoBox
                title="You have no tables yet!"
                text="Start by adding a table to your plan."
                icon={<TableBarOutlinedIcon />}
              />
            )}

            <Box sx={{ position: "relative", mb: 6 }}>
              <Box sx={styles.modeHeader}>
                <Box sx={{ flexGrow: 1 }}>
                  <IconButton onClick={() => handleSaveLock(!plan.isLocked)}>
                    {plan.isLocked ? (
                      <LockOutlinedIcon />
                    ) : (
                      <LockOpenOutlinedIcon />
                    )}
                  </IconButton>
                </Box>

                <Box>
                  <AddTable planId={Number(plan.id)} />
                </Box>
              </Box>
              <Box sx={[styles.tablesContainer]}>
                <Box sx={[styles.tableMoveableWrapper, { zoom: `${zoom}%` }]}>
                  {tables.map((table, index) => (
                    <Table
                      key={`table-${index}`}
                      table={table}
                      tableIndex={index}
                      arrangeMode={!plan.isLocked}
                      onUpdateTablePos={onUpdateTablePos}
                    />
                  ))}
                </Box>
              </Box>
              <Box sx={styles.zoomContainer}>
                <ButtonGroup>
                  <Button
                    sx={styles.zoomButton}
                    onClick={() => setZoom((prevZoom) => prevZoom - 10)}
                  >
                    <ZoomOut />
                  </Button>
                  <Button
                    sx={styles.zoomButton}
                    onClick={() => setZoom((prevZoom) => prevZoom + 10)}
                  >
                    <ZoomIn />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </>
        )}
      </Box>

      {plan && (
        <EditPlanDialog
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          plan={plan}
          setPlan={setPlan}
        />
      )}

      <StyledDialog
        open={openQRCode}
        handleClose={() => setOpenQRCode(false)}
        title="QR Code"
        actions={
          <>
            <Button onClick={() => setOpenQRCode(false)}>Close</Button>
            <Button onClick={saveQRCode} variant="contained">
              Download
            </Button>
          </>
        }
      >
        <Typography variant="body2">
          Download the QR Code! You can share it to your guests ahead of time,
          and/or print out this QR Code to be set up at the venue of your event!
          The QR Code will be directed to the seating chart of your event which
          can be viewed{" "}
          <MuiLink
            href={`${window.location.origin}/event/${planId}`}
            target="_blank"
          >
            here
          </MuiLink>
          .
        </Typography>
        <QRCode
          value={`${window.location.origin}/event/${planId}`}
          id="divToPrint"
        />
      </StyledDialog>
    </Box>
  );
};

export default PlanPage;
