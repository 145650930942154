import { Box, Typography } from "@mui/material";
import styles from "./styles";

const StyledTitle = ({
  children,
  cta,
}: {
  children: React.ReactNode;
  cta?: React.ReactNode;
}) => {
  return (
    <Box sx={styles.root}>
      <Typography variant="h2" style={{ flexGrow: 1 }}>
        {children}
      </Typography>
      {cta}
    </Box>
  );
};

export default StyledTitle;
