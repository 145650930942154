import React, {
  createContext,
  useState,
  SetStateAction,
  useContext,
} from "react";
import { FaveBox } from "../schemas/box";
import { User } from "../schemas/user";
import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "./alert";

interface UserContextValue {
  userBoxes: FaveBox[];
  setUserBoxes: React.Dispatch<SetStateAction<FaveBox[]>>;
  currentUser: User | null;
  setCurrentUser: React.Dispatch<SetStateAction<User | null>>;
  isAdmin: boolean;
  authFetch: (url: string, method: string, body: any) => Promise<any>;
}

const initialContextValue: UserContextValue = {
  userBoxes: [],
  setUserBoxes: () => {},
  currentUser: null,
  setCurrentUser: () => {},
  isAdmin: false,
  authFetch: async () => {},
};

const UserContext = createContext<UserContextValue>(initialContextValue);

interface FaveDudProviderProps {
  children: React.ReactNode;
}

const UserProvider = ({ children }: FaveDudProviderProps) => {
  const { getToken } = useAuth();
  const [userBoxes, setUserBoxes] = useState<FaveBox[]>([]);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const { setAlertMessage } = useContext(AlertContext);

  const isAdmin = currentUser ? currentUser.isAdmin : false;

  const authFetch = async (
    url: string,
    method: string = "GET",
    body: any = {}
  ) => {
    const token = await getToken({ template: "seatmap" });

    const requestOptions = {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: method === "GET" ? undefined : JSON.stringify(body),
    };

    return fetch(url, requestOptions).then((res) => {
      const json_response = res.json();
      if (res.ok) {
        return json_response;
      } else {
        setAlertMessage({
          message:
            "Something went wrong! Please refresh and try again. If the problem persists, contact support.",
          severity: "error",
        });
        throw new Error("Request failed");
      }
    });
  };

  return (
    <UserContext.Provider
      value={{
        userBoxes,
        setUserBoxes,
        currentUser,
        setCurrentUser,
        isAdmin,
        authFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
