import { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import StyledTitle from "../../components/StyledTitle";
import { UserContext } from "../../contexts/user";
import { useParams, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import BackButton from "../../components/BackButton";
import routes from "../../utils/routes";
import { AlertContext } from "../../contexts/alert";
import styles from "./styles";
import { PreviewTable } from "../../schemas/table";

const WeddingPlannerSvg = require("../../assets/decor/wedding-planner.png");

const AutoAssignPage = () => {
  const { planId } = useParams();
  const [searchParams, _] = useSearchParams();

  const { authFetch } = useContext(UserContext);
  const { setAlertMessage } = useContext(AlertContext);
  const [autoAssignPreviewLoading, setAutoAssignPreviewLoading] =
    useState<boolean>(false);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [previewTables, setPreviewTables] = useState<PreviewTable[]>([]);
  const [message, setMessage] = useState<string>("");
  const [history, setHistory] = useState<any>([]);

  const userHistory = useMemo(() => {
    return history.filter(
      (chat: any, idx: number) => chat.role === "user" && idx > 0
    );
  }, [history]);

  const chatHistoryRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    chatHistoryRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onAutoAssignPreview = async () => {
    setAutoAssignPreviewLoading(true);
    setChatLoading(true);

    authFetch(`/api/seats/auto-assign-preview`, "POST", {
      planId,
      chatHistory: history,
      userInput: message,
    })
      .then((data) => {
        console.log(data);
        setHistory(data.chatHistory);
        setPreviewTables(data.preview.tables);
        setMessage("");
      })
      .finally(() => {
        setAutoAssignPreviewLoading(false);
        setChatLoading(false);
      })
      .catch((err) => {
        setAlertMessage({
          message: "Something went wrong!",
          severity: "error",
        });
        console.log(err);
      });
  };

  const onCreateTable = async (table: PreviewTable) => {
    if (!table) return;

    authFetch(`/api/plans/${planId}/tables`, "POST", {
      number: table.number,
      autoAssignGuests: table.guests,
    })
      .then((data) => {
        console.log(data);
        setAlertMessage({
          message: "Table created successfully!",
          severity: "success",
        });
        setPreviewTables((prevTables) =>
          prevTables.filter((t) => t.number !== table.number)
        );
      })
      .catch((err) => {
        setAlertMessage({
          message: "Something went wrong!",
          severity: "error",
        });
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchParams.get("autoStart") === "true" && history.length === 0) {
      onAutoAssignPreview();
    }
  }, [searchParams]);

  useEffect(() => {
    if (autoAssignPreviewLoading) scrollToBottom();
  }, [autoAssignPreviewLoading]);

  useEffect(() => {
    scrollToBottom();
  }, [history]);

  return (
    <Box sx={{ minWidth: "100%" }}>
      <BackButton path={`${routes.plans}/${planId}`} title="Back to Plan" />
      <StyledTitle>Auto Assign Guests</StyledTitle>

      {searchParams.get("autoStart") !== "true" && (
        <LoadingButton
          variant="outlined"
          sx={styles.autoAssignButton}
          onClick={onAutoAssignPreview}
          loading={autoAssignPreviewLoading}
        >
          <span>Auto-Assign</span>
        </LoadingButton>
      )}

      {/* <Button variant="outlined">Start Editing from here</Button> */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack
            gap={2}
            direction="row"
            justifyContent={"center"}
            sx={{ my: 2 }}
            flexWrap="wrap"
          >
            {previewTables.map((table) => (
              <Card key={table.number} sx={{ width: "max-content" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Table {table.number}
                  </Typography>
                  <Stack spacing={0.5}>
                    {table.guests.map((guest, idx) => (
                      <Typography
                        key={`table-${table.number}-guest-${idx}`}
                        variant="body2"
                      >
                        {guest}
                      </Typography>
                    ))}
                  </Stack>
                </CardContent>
                <CardActions>
                  {/* On create, remove table */}
                  <Button size="small" onClick={() => onCreateTable(table)}>
                    Create Table
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={styles.chatHistory}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Box
                component="img"
                src={WeddingPlannerSvg}
                sx={styles.weddingPlanner}
              />
              <Box sx={[styles.chatBubble, styles.plannerBubble]}>
                <Typography variant="body2">
                  Hello! My name is Belle and I will be helping you with your
                  seating plan. Let me suggest a seating arrangement for your
                  guests! What do you think? Let me know any changes you'd like
                  to make.
                </Typography>
              </Box>
            </Stack>

            {userHistory.map((chat: any, idx: number) => (
              <Stack key={`chat-${idx}`} spacing={1}>
                <Box sx={styles.chatRow}>
                  <Stack direction="row" spacing={1} justifyContent={"end"}>
                    <Box sx={[styles.chatBubble, styles.userBubble]}>
                      <Typography variant="body2">{chat.parts[0]}</Typography>
                    </Box>
                  </Stack>
                </Box>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Box
                    component="img"
                    src={WeddingPlannerSvg}
                    sx={styles.weddingPlanner}
                  />
                  <Box sx={[styles.chatBubble, styles.plannerBubble]}>
                    <Typography variant="body2">
                      Got it! Let me work on that for you.
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Box
                    component="img"
                    src={WeddingPlannerSvg}
                    sx={styles.weddingPlanner}
                  />
                  <Box sx={[styles.chatBubble, styles.plannerBubble]}>
                    <Typography variant="body2">
                      Here's a revised seating arrangement. What do you think?
                    </Typography>
                  </Box>
                </Stack>

                <Box ref={chatHistoryRef} />
              </Stack>
            ))}

            {chatLoading && (
              <>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Box sx={[styles.chatBubble, styles.userBubble]}>
                    <Typography variant="body2">{message}</Typography>
                  </Box>
                </Stack>

                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Box
                    component="img"
                    src={WeddingPlannerSvg}
                    sx={styles.weddingPlanner}
                  />
                  <Box sx={[styles.chatBubble, styles.plannerBubble]}>
                    <Typography variant="body2">
                      Got it! Let me work on that for you.
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={styles.typingBubbleContainer}>
                  <Box component="span" sx={styles.typingBubble} />
                  <Box component="span" sx={styles.typingBubble} />
                  <Box component="span" sx={styles.typingBubble} />
                </Box>
              </>
            )}
          </Box>
          <Stack spacing={1} direction={"row"}>
            <TextField
              placeholder="Write your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              autoComplete="off"
            />
            <Button
              variant="contained"
              type="submit"
              onClick={onAutoAssignPreview}
            >
              Send
            </Button>
          </Stack>

          <FormHelperText>
            You can tell the assistant more info for example: "Place John and
            Jane at the head table at Table 1" or "Samantha and Vincent cannot
            sit together".
          </FormHelperText>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutoAssignPage;
