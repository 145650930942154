import { useContext, useState } from "react";

import { Box, Link, Stack, TextField, Typography } from "@mui/material";
import StyledTitle from "../../components/StyledTitle";
import { GuestsContext } from "../../contexts/guests";
import { UserContext } from "../../contexts/user";
import { Guest, PreviewGuest } from "../../schemas/guest";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import BackButton from "../../components/BackButton";
import routes from "../../utils/routes";
import PreviewImportGuests from "../../components/PreviewImportGuests";

const AddGuestsPage = () => {
  const { planId } = useParams();
  const { setGuests } = useContext(GuestsContext);
  const { authFetch } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Guest>({
    name: "",
  });
  const [importData, setImportData] = useState<string>("");
  const [previewGuests, setPreviewGuests] = useState<PreviewGuest[]>([]);
  const [importLoading, setImportLoading] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    authFetch(`/api/plans/${planId}/add-guest`, "POST", formData)
      .then((data) => {
        setGuests((prevGuests) => [...prevGuests, data.guest]);
        setFormData({ name: "" });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target?.result as string;

      console.log(text);
      setImportData(text);
    };

    reader.readAsText(file);
  };
  const onImport = () => {
    if (!importData || !planId) return;

    setImportLoading(true);

    authFetch(`/api/guests/import`, "POST", {
      planId: planId,
      data: importData,
    })
      .then((data) => {
        setPreviewGuests(data.guests);
        setImportLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <BackButton path={`${routes.plans}/${planId}`} title="Back to Plan" />
      <StyledTitle>Add Guests</StyledTitle>
      <form onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Name"
              variant="outlined"
              onChange={handleChange}
              name="name"
              value={formData.name}
              autoComplete="off"
            />
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>

      <Typography variant="h3">Import CSV</Typography>
      <Typography variant="body1">
        You can import a CSV file with the names of the guests you want to add.{" "}
        <Link
          href="https://docs.google.com/spreadsheets/d/1alCozFhHSvZrrrtXhd7Kt8AP6iXVprh5G4a8l2kEy14/edit?usp=sharing"
          target="_blank"
        >
          Click here
        </Link>{" "}
        for the template that you can download and edit.
      </Typography>
      <input type="file" accept=".csv" onChange={onFileChange} id="import" />
      <LoadingButton
        onClick={onImport}
        variant="outlined"
        loading={importLoading}
      >
        Upload
      </LoadingButton>

      {planId && importData && previewGuests.length > 0 && (
        <PreviewImportGuests
          previewGuests={previewGuests}
          setPreviewGuests={setPreviewGuests}
          planId={Number(planId)}
        />
      )}
    </Box>
  );
};

export default AddGuestsPage;
