const defaultStyles = {
  root: {},
  guestItem: {
    borderBottom: "1px solid white",
    flexDirection: "column",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    whiteSpace: "normal !important",
  },
  activeGuestItem: {
    backgroundColor: "green.main",
    color: "white",
  },
  inactiveItem: {
    opacity: 0.3,
  },
  guestName: { width: "100%" },
  tableLabel: {
    position: "absolute",
    top: 0,
    right: 0,
    px: 1,
    py: 0.3,
    backgroundColor: "white",
    borderBottomLeftRadius: "8px",
    boxShadow: 3,
    fontSize: 11,
  },
};

export default defaultStyles;
