import React, { createContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

interface AlertContextValue {
  openAlert: boolean;
  setAlertMessage: ({ message, severity }: AlertMessage) => void;
}

const initialContextValue: AlertContextValue = {
  openAlert: false,
  setAlertMessage: () => {},
};

const AlertContext = createContext<AlertContextValue>(initialContextValue);

interface AlertProviderProps {
  children: React.ReactNode;
}

type AlertMessage = {
  message: string;
  severity: "success" | "info" | "warning" | "error";
};

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({
    message: "",
    severity: "success",
  });

  const handleClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (alertMessage.message) {
      setOpenAlert(true);
    }
  }, [alertMessage]);

  return (
    <AlertContext.Provider
      value={{
        openAlert,
        setAlertMessage,
      }}
    >
      {children}
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
